import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Dot } from 'app/components/Common/Dot'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  images: ImageProps[]
  label?: string
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  images,
  label,
  title,
}: Props) {
  if (!images) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (galleryRef) {
        galleryRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container ref={containerRef}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {cta ? (
            <Media greaterThanOrEqual="desktopSmall">
              <FadeInUp>
                <CTA {...cta} />
              </FadeInUp>
            </Media>
          ) : null}
        </Head>

        <Wrapper>
          <Carousel ref={sliderRef}>
            {uniq(images).map((item, index) => (
              <Slide key={index} className="keen-slider__slide">
                <Inner style={positionStyle(index)}>
                  <Image
                    alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                    animationStart={
                      loaded[index] || nextLoaded[index] ? true : false
                    }
                    {...(loaded[index] || nextLoaded[index] ? item : null)}
                  />

                  <Spinner />
                </Inner>
              </Slide>
            ))}
          </Carousel>

          {images.length > 1 ? (
            <Dots dial={5} row>
              {images.map((_item, index) => (
                <Dot
                  key={index}
                  className={currentSlide === index ? 'active' : undefined}
                  onClick={() => {
                    galleryRef.current?.moveToIdx(index)
                  }}
                />
              ))}
            </Dots>
          ) : null}
        </Wrapper>

        {cta ? (
          <Media lessThan="desktopSmall">
            <FadeInUp>
              <CTA {...cta} />
            </FadeInUp>
          </Media>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 18.75rem auto 21.25rem;
  padding: 0 8.333vw;
  position: relative;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
    padding: 0 1.5rem;
    text-align: center;
  }
`

const Head = styled.div`
  position: relative;
  z-index: 2;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  margin-bottom: 4.5rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 32rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.25rem;
  font-weight: 300;
  line-height: 7.75rem;

  @media (max-width: 1199px) {
    max-width: 21rem;
    font-size: 3.75rem;
    line-height: 4.625rem;
    margin: auto;
  }
`

const CTA = styled(Button)`
  margin-top: 2.75rem;

  @media (max-width: 1199px) {
    margin-top: 4.75rem;
  }
`

const Wrapper = styled.div`
  width: calc(100% - 8.333vw - 32rem);
  height: 71.1vh;
  max-height: 40rem;
  position: absolute;
  top: 0;
  right: 8.333vw;

  @media (max-width: 1199px) {
    width: 100%;
    height: 66.8vh;
    max-height: 17.5rem;
    margin-top: 2.5rem;
    position: relative;
    top: auto;
    right: 0;
  }
`

const Carousel = styled.div`
  display: flex;
  height: 100%;
  outline: none;
  overflow: hidden;
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`

const Dots = styled(FlexBox)`
  position: absolute;
  bottom: -2.875rem;
  left: 0;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    position: relative;
    bottom: auto;
    left: auto;

    > div {
      margin: 0 1.5625rem;
    }
  }
`
