import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Facebook, Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

export interface Props {
  facebookURL?: string
  images: ImageProps[]
  instagramURL?: string
  label?: string
}

export const SocialWall = memo(function SocialWall({
  facebookURL,
  images,
  instagramURL,
  label,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <Hashtag>#concapark</Hashtag>
      </FadeInUp>

      {facebookURL || instagramURL ? (
        <FadeInUp>
          <Social dial={5} row>
            {facebookURL ? (
              <Anchor
                aria-label="Facebook"
                href={facebookURL}
                rel="noreferrer"
                target="_blank"
              >
                <Facebook />
              </Anchor>
            ) : null}

            {instagramURL ? (
              <Anchor
                aria-label="Instagram"
                href={instagramURL}
                rel="noreferrer"
                target="_blank"
              >
                <Instagram />
              </Anchor>
            ) : null}
          </Social>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <Mosaic
          options={{
            gutter:
              typeof window !== 'undefined' && window.innerWidth > 1199
                ? 36
                : 24,
            percentPosition: true,
            transitionDuration: 0,
          }}
        >
          {uniq(images)
            .slice(0, 8)
            .map((item, index) => (
              <Item key={index}>
                <LazyLoadComponent>
                  <Image {...item} />
                </LazyLoadComponent>
              </Item>
            ))}
        </Mosaic>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.625rem auto 6.25rem;
  padding: 0 2.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
    padding: 0 1.5rem;
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  margin-bottom: 0.625rem;
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.4375rem;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
  }
`

const Social = styled(FlexBox)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`

const Anchor = styled.a`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryLight};
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark6};
    margin: 0 0.9375rem;
    transition: 0.3s ease-in-out;
  }
`

const Mosaic = styled(Masonry)<MasonryProps>`
  margin: 1.5rem -2.25rem 0 0;

  @media (max-width: 1199px) {
    margin-right: -1.5rem;
  }
`

const Item = styled.div`
  width: calc(25% - 2.25rem);
  height: 46vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  margin-top: 2.25rem;
  position: relative;
  &:nth-of-type(2) {
    height: 61vh;
  }
  &:nth-of-type(4),
  &:nth-of-type(6) {
    height: 35vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 1.5rem);
    height: 8.3125rem;
    margin-top: 1.5rem;
    &:nth-of-type(2) {
      height: 14.8125rem;
    }
    &:nth-of-type(4),
    &:nth-of-type(6) {
      height: 11.125rem;
    }
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
