import styled from '@emotion/styled'
import { AdvancedIntro } from 'app/components/AdvancedIntro'
import { BannerGallery } from 'app/components/BannerGallery'
import { Hero } from 'app/components/Hero'
import { Location } from 'app/components/Location'
import { RoomsPreview } from 'app/components/RoomsPreview'
import { SEO } from 'app/components/SEO'
import { ServicesPreview } from 'app/components/ServicesPreview'
import { SlidingText } from 'app/components/SlidingText'
import { SocialWall } from 'app/components/SocialWall'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Visual } from 'app/components/Visual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {context.structuredDataProps ? (
          <StructuredData
            languageCode={pageContext.languageCode}
            {...context.structuredDataProps}
          />
        ) : null}
        {context.headerProps ? (
          <Header
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            pageID={context.id}
            {...context.headerProps}
          />
        ) : null}
        {context.heroProps ? (
          <Hero hasVideo={true} {...context.heroProps} />
        ) : null}
        {context.advancedIntroProps ? (
          <AdvancedIntro {...context.advancedIntroProps} />
        ) : null}
        {context.bannerGalleryProps ? (
          <BannerGallery {...context.bannerGalleryProps} />
        ) : null}
        {context.roomsPreviewProps ? (
          <RoomsPreview {...context.roomsPreviewProps} />
        ) : null}
        {context.visualProps ? <Visual {...context.visualProps} /> : null}
        {context.visual2Props ? (
          <Visual variant="flip" {...context.visual2Props} />
        ) : null}
        {context.servicesPreviewProps ? (
          <ServicesPreview {...context.servicesPreviewProps} />
        ) : null}
        {context.locationProps ? <Location {...context.locationProps} /> : null}
        {context.socialWallProps ? (
          <SocialWall {...context.socialWallProps} />
        ) : null}
        {context.slidingTextProps ? (
          <Media greaterThanOrEqual="desktopSmall">
            <SlidingText {...context.slidingTextProps} />
          </Media>
        ) : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            {...context.footerProps}
          />
        ) : null}
        <style type="text/css">{mediaStyle}</style>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
