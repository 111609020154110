import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  cards: CardProps[]
  cta?: ButtonProps
  label?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cards,
  cta,
  label,
  title,
}: Props) {
  if (!cards) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}

      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <List dial={2} row wrap>
        {cards.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </List>

      {cta ? (
        <FadeInUp>
          <CTA {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 18.75rem auto 15.625rem;
  padding: 0 8.333vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
    padding: 0 1.5rem;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 12.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 4.375rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const List = styled(FlexBox)`
  margin: 4.125rem -4.6875rem 0 0;

  @media (max-width: 1199px) {
    margin: 2.375rem 0 0;
  }
`

const CTA = styled(Button)`
  margin-top: 4.5rem;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`
