import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
}

export const Location = memo(function Location({
  cta,
  description,
  images,
}: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper>
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>

          {cta ? (
            <Media greaterThanOrEqual="desktopSmall">
              <FadeInUp>
                <CTA {...cta} />
              </FadeInUp>
            </Media>
          ) : null}
        </Wrapper>

        {images ? (
          <Background row wrap>
            {images.map((item, index) => (
              <LazyLoadComponent key={index}>
                <Image {...item} />
              </LazyLoadComponent>
            ))}
          </Background>
        ) : null}

        {cta ? (
          <Media lessThan="desktopSmall">
            <FadeInUp>
              <CTA {...cta} />
            </FadeInUp>
          </Media>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.625rem auto;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem auto 6.25rem;
  }
`

const Wrapper = styled.div`
  padding: 0 8.333vw;

  @media (max-width: 1199px) {
    padding: 0 1.5rem;
  }
`

const Description = styled.div`
  max-width: 53.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.375rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`

const Background = styled(FlexBox)`
  margin-top: 6.25rem;

  picture {
    width: 50%;
    height: 77.8vh;
    max-height: 43.75rem;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;

    picture {
      height: 48.6vh;
      max-height: 13rem;
    }
  }
`
