import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  label?: string
  title?: string
}

export const Card = memo(function Card({ image, label, title }: Props) {
  return (
    <Container>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {label ? (
        <Label dial={4} row>
          {label}
        </Label>
      ) : null}

      {title ? <Title>{title}</Title> : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(33.333% - 4.6875rem);
  height: 66.7vh;
  max-height: 37.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-weight: 300;
  margin-right: 4.6875rem;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark6};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:nth-of-type(odd) {
    transform: translateY(-9.375rem);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: 53.7vh;
    max-height: 14.375rem;
    margin: 0.125rem 0 0;
    &:nth-of-type(odd) {
      transform: none;
    }
  }
`

const Label = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  line-height: 1.875rem;
  position: absolute;
  top: 3.75rem;
  right: 5.125rem;
  z-index: 2;
  transform-origin: right top;
  transform: rotate(-90deg);

  &:after {
    content: '';
    width: 2.5rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin-left: 1.25rem;
    transform: translateY(0.125rem);
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Title = styled.h2`
  max-width: 7.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  position: absolute;
  right: 3.75rem;
  bottom: 3.75rem;
  left: 3.75rem;
  z-index: 2;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.75rem;
    line-height: 2.125rem;
    top: 50%;
    right: 1.5rem;
    bottom: auto;
    left: 1.5rem;
    text-align: center;
    transform: translateY(-50%);
  }
`
